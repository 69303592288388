<script>
export default {
  data() {
    return {
      
    };
  },
  props: {
    label: {
      type: String,
      default: 'Ok',
    },
    type: {
      type: String,
      default: 'submit',
    },
    btnClass: {
      type: String,
      default: 'btn btn-primary w-md waves-effect waves-light'
    }
  },
  emits:['click'],
  mounted() {
  },
  methods: {
    click(){
      this.$emit('click');
    }
  }
};
</script>

<template>
  <button
        :class="btnClass"
        :type="type"
        :disabled="$store.state.loading.loading"
        @click="click()"
    >
    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="$store.state.loading.loading"></span>
    {{ label }}</button>
</template>