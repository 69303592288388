import { mapState, mapGetters, mapActions } from 'vuex'

export const authComputed = {
  ...mapState('auth', {
    currentUser: (state) => state.currentUser,
  }),
  ...mapGetters('auth', ['loggedIn']),
}

export const layoutComputed = {
  ...mapState('layout', {
    layoutType: (state) => state.layoutType,
    leftSidebarType: (state) => state.leftSidebarType,
    layoutWidth: (state) => state.layoutWidth,
    topbar: (state) => state.topbar,
    loader: (state) => state.loader
  })
}

export const loadingComputed = {
  ...mapState('loading', {
    loading: (state) => state.loading,
  }),
}

export const identificationTypeComputed = {
  ...mapState('identificationType', {
    identificationTypes: (state) => state.identificationTypes,
  }),
}

export const productCategoryComputed = {
  ...mapState('productCategory', {
    productCategories: (state) => state.productCategories,
  }),
  ...mapGetters('productCategory', ['categoryTree']),
}

export const storeComputed = {
  ...mapState('store', {
    stores: (state) => state.stores,
  }),
}

export const customerComputed = {
  ...mapState('customer', {
    customers: (state) => state.customers,
  }),
}

export const permissionComputed = {
  ...mapState('permission', {
    permissions: (state) => state.permissions,
  }),
  ...mapGetters('permission', ['hasPermission']),
}

export const menuComputed = {
  ...mapState('menu', {
    menus: (state) => state.menus,
  }),
}

export const roleComputed = {
  ...mapState('role', {
    roles: (state) => state.roles,
  }),
}

export const authMethods = mapActions('auth', ['login', 'logout', 'register', 'forgotPassword', 'validateResetToken', 'resetPassword']);

export const layoutMethods = mapActions('layout', ['changeLayoutType', 'changeLayoutWidth', 'changeLeftSidebarType', 'changeTopbar', 'changeLoaderValue']);

export const notificationMethods = mapActions('notification', ['success', 'error', 'clear']);

export const loadingMethods = mapActions('loading', ['changeLoading']);

export const identificationTypeMethods = mapActions('identificationType', ['loadList']);
export const storeMethods = mapActions('store', ['loadList']);
export const productCategoryMethods = mapActions('productCategory', ['loadList']);

export const customerMethods = mapActions('customer', ['loadList']);
export const roleMethods = mapActions('role', ['loadList']);
export const permissionMethods = mapActions('permission', ['loadList']);
export const menuMethods = mapActions('menu', ['loadList']);
